import _ from "lodash";
import VMasker from "vanilla-masker";
import { Controller } from "stimulus";
import moment from "moment";

// AmountController controls the way buttons and menus are displayed when a
// preset amount is selected, or a custom amount is input.
export default class extends Controller {
  static targets = [
    "amount",
    "amountOther",
    "amountPreset",
    "amountPreview",
    "amountOtherEnabled",
    "checkboxRecurring",
    "frequencyOption",
    "feesCheckbox",
    "paymentMethodType",
    "savedPaymentMethod",
    "estimatedFees",
    "grossedUpAmount",
    "netAmount",
    "displayAmount",
    "finalAmount",
    "frequency",
    "starting",
    "startsOn",
    "startsOnContainer",
  ];

  static values = {
    displayAmount: Number,
  };

  // Immediately populate the amount field with the selected amount.
  connect() {
    this.amount = this.amountTarget.value;
    // this.populateAmountAndFees();
  }

  // Public method
  // Update the amount or grossed-up amount
  populateAmountAndFees() {
    this.willCoverFees
      ? this.updateAmountWithFees()
      : this.updateAmountWithoutFees();
    this.grossedUpAmount = this.amountWithFees;
    this.estimatedFees = this.calculateFees;
    this.netAmount = this.amountTarget.value;
    this.updateStartsOn();
    this.updateFrequency();
    this.applyDefaultFrequencyOption();
  }

  updateAmountWithFees() {
    this.displayAmount = this.amountWithFees;
    if (this.hasFinalAmountTarget)
      this.finalAmountTarget.value = this.amountWithFees;
  }

  updateAmountWithoutFees() {
    this.displayAmount = this.amount;
    if (this.hasFinalAmountTarget) this.finalAmountTarget.value = this.amount;
  }

  // Public
  updateStartsOn() {
    if (this.hasStartingTarget && this.hasStartsOnTarget) {
      if (this.recurring) {
        this.startingTarget.innerHTML = "Starting " + this.selectedStartsOn;
        this.startsOnVisible = true;
      } else {
        this.startingTarget.innerHTML = "Now";
        this.startsOnVisible = false;
      }
    }
  }

  set startsOnVisible(bool) {
    if (this.hasStartsOnContainerTarget) {
      if (bool) {
        this.startsOnContainerTarget.classList.remove("hidden");
      } else {
        this.startsOnContainerTarget.classList.add("hidden");
      }
    }
  }

  // Public
  updateFrequency() {
    if (this.hasFrequencyTarget) {
      if (this.recurring) {
        var frequencyText = this.selectedFrequencyOption;
        if (frequencyText) {
          this.frequencyTarget.innerHTML = frequencyText.replace(
            /[_\s]+/g,
            " "
          );
        }
      }
    }
  }

  get recurring() {
    if (this.hasCheckboxRecurringTarget) {
      return this.checkboxRecurringTarget.checked;
    } else {
      return this.selectedFrequencyOption != "One time";
    }
  }

  set displayAmount(value) {
    this.displayAmountValue = value;

    if (!this.hasDisplayAmountTarget) {
      return;
    }

    let str = this.maskMoney(value);

    this.displayAmountTargets.forEach((element) => {
      element.innerHTML = str;
    });
  }

  set grossedUpAmount(value) {
    if (!this.hasGrossedUpAmountTarget) {
      return;
    }

    let str = this.maskMoney(value);

    this.grossedUpAmountTargets.forEach((element) => {
      element.innerHTML = str;
    });
  }

  set netAmount(value) {
    if (!this.hasNetAmountTarget) {
      return;
    }
    let str = this.maskMoney(value);

    this.netAmountTargets.forEach((element) => {
      element.innerHTML = str;
    });
  }

  // Public method selectOther
  // Called when the 'other' button is selected
  selectOther(event) {
    if (this.amountOtherTarget) {
      this.amount = this.previousOtherAmount || null;
      this.amountOtherTarget.checked = true;
      this.amountTarget.focus();
    }
  }

  showAmountField(event) {
    this.amountTarget.classList.remove("hidden");
  }

  hideAmountField(event) {
    this.amountTarget.classList.add("hidden");
  }

  // Public method selectAmount
  // Used when clicking a preset amount.
  selectAmount(event) {
    this.amount = event.target.dataset.amount;
  }

  // PublicMethod changeOther
  // called when the Amount field is blurred
  changeOther() {
    this.amount = parseFloat(this.amountTarget.value.replace(/,/, "."));
    this.previousOtherAmount = this.amountTarget.value;
  }

  // Public method liveAmount
  // Debounces the changeOther method
  liveAmount() {
    return _.debounce(this.populateAmountAndFees, 100);
  }

  // TargetConnected
  amountOtherTargetConnected(target) {
    var amountSelected = this.amountPresetTargets.some((a) => {
      return a.checked;
    });

    // If there's no amount checked, then check the other option.
    if (!amountSelected) {
      target.checked = true;
    }
  }

  amountPresetTargetConnected(target) {
    if (parseFloat(target.dataset.amount) == parseFloat(this.amount)) {
      target.checked = true;
    }
  }

  // Disable the amount field if the amountOtherTarget is present
  // If the Other field is disabled, then select the first preset amount.
  amountOtherEnabledTargetConnected(target) {
    if (target.value == "true") {
      this.amountTarget.disabled = false;
    } else {
      this.amountTarget.disabled = true;

      if (!this.presetAmounts.includes(this.amount.toString())) {
        // select the first item and set the amount field
        var preset = this.amountPresetTargets[0];
        preset.checked = true;
        this.amount = preset.dataset.amount;
      }
    }
  }

  get presetAmounts() {
    return this.amountPresetTargets.map((a) => a.dataset.amount);
  }

  // Unused?
  get presetAmountSelected() {
    return (
      this.amountPresetTargets.filter((el) => el.checked).length > 0 ||
      this.amountOtherTarget.checked
    );
  }

  // Calculate fees, populate estimated fees field, and update amount display
  // targets.
  //
  // To calculate the fees, we need to know:
  // - amount (float)
  // - processing rates (hash with percent and fixed for card and bank)
  // - whether gross up (boolean)
  // - selected payment type (card or bank)
  //
  // We will output:
  // - Button label
  // - Estimated fees field
  set amount(value) {
    if (parseFloat(value) > 0) {
      var val = parseFloat(value).toFixed(2);
      this.amountTarget.value = val;

      this.populateAmountAndFees();
    } else {
      this.amountTarget.value = "";
    }
  }

  set estimatedFees(value) {
    if (this.hasEstimatedFeesTarget) {
      this.estimatedFeesTarget.value = value;
    }
    // Display label Fee
    // this.feesLabelTarget.innerHTML = `Add $${value} to cover transaction fees`;
  }

  // Getters
  get willCoverFees() {
    if (this.hasFeesCheckboxTarget) {
      return this.feesCheckboxTarget.checked;
    }
  }

  changeSelectedPaymentMethod(event) {
    // FIXME: There's a lot of overlap with selectedPaymentMethod
    if (event.target.type == "select-one") {
      this.paymentMethodTypeTarget.value =
        event.target[event.target.selectedIndex].dataset.paymentMethodType;
    } else {
      this.paymentMethodTypeTarget.value =
        event.target.dataset.paymentMethodType;
    }

    this.populateAmountAndFees();
  }

  get selectedPaymentMethodType() {
    if (!this.hasPaymentMethodTypeTarget) {
      return;
    }

    if (typeof this.paymentMethodTypeTarget.options == "undefined") {
      return this.paymentMethodTypeTarget.value;
    } else {
      return (
        this.paymentMethodTypeTarget[this.paymentMethodTypeTarget.selectedIndex]
          .dataset.paymentMethodType || "card"
      );
    }
  }

  get selectedStartsOn() {
    if (this.hasStartsOnTarget) {
      var date = moment(this.startsOnTarget.value, "MM/DD/YYYY");
      return moment(date).calendar({
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "MMM D, YYYY",
        sameElse: "MMM D, YYYY",
      });
    }
  }

  get pct() {
    return parseFloat(this.selectedFees["pct"]);
  }

  get fixed() {
    return parseFloat(this.selectedFees["fixed"]);
  }

  get goal() {
    return parseFloat(this.amount);
  }

  get amount() {
    return this.amountTarget.value || 0;
  }

  get processorRates() {
    return JSON.parse(this.data.get("processorRates"));
  }

  get selectedFees() {
    if (this.processorRates !== null) {
      return this.processorRates[this.selectedPaymentMethodType];
    }
  }

  // Perform the fees calculation
  get calculateFees() {
    if (!this.hasFeesCheckboxTarget) {
      return;
    }
    if (this.willCoverFees) {
      // Gross up fees
      var f = parseFloat(this.amountWithFees) - parseFloat(this.amount);
    } else {
      // Fees without gross up
      var f = parseFloat(this.amount * this.pct + this.fixed);
    }
    return f.toFixed(2);
  }

  // Calculate the "gross up" fees
  get amountWithFees() {
    if (!this.hasFeesCheckboxTarget) {
      return;
    }

    var charge = (this.goal + this.fixed) / (1 - this.pct);
    return charge.toFixed(2);
  }

  // Button label

  maskMoney(value) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
    const nf = new Intl.NumberFormat("en-US", {
      currency: "USD",
      style: "currency",
    });
    let str = nf.format(value);
    // Remove cents?
    // don't use NumberFormat's {style: 'currency'} because it always adds
    // cents, but we don't want to display cents if the cents are ".00"
    // str = str.replace(/\.00/, "");
    return str;
  }

  get currencySymbol() {
    return "$";
  }

  applyDefaultFrequencyOption() {
    if (this.selectedFrequencyOption == null && this.hasFrequencyOptionTarget) {
      this.frequencyOptionTargets[0].checked = true;
    }
  }

  get selectedFrequencyOption() {
    var elems = this.frequencyOptionTargets;
    for (var i = 0, length = elems.length; i < length; i++) {
      if (elems[i].type == "hidden") {
        return elems[i].value;
      }
      if (elems[i].checked) {
        return elems[i].nextSibling.innerHTML.replace(/&amp;/g, "&");
      }
    }
  }
}
