import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["accountId", "form", "shouldUpdateAmounts"];

  submit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (
      this.currentAccountId != "" &&
      this.currentAccountId != this.accountIdTarget.value
    ) {
      this.shouldUpdateAmountsTarget.value = confirm(this.promptQuestion);
    } else {
      this.shouldUpdateAmountsTarget.value = "false";
    }

    this.formTarget.submit();
  }

  get currentAccountId() {
    return this.data.get("current-account-id") || "";
  }

  get promptQuestion() {
    return this.data.get("account-id-prompt-question") || "";
  }
}
