import { Controller } from "stimulus";

import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config.js";
const fullConfig = resolveConfig(tailwindConfig);

export default class extends Controller {
  static targets = ["color"];

  connect() {
    this.colorTarget.innerHTML =
      fullConfig.theme.colors[this.data.get("color")][this.data.get("shade")];
  }
}
