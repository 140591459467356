import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["sortIcon", "tableContainer"];

  sort(e) {
    e.preventDefault();

    const currentTarget = e.currentTarget;
    if (currentTarget.dataset["disabled"] == "true") {
      return;
    }

    currentTarget.dataset["disabled"] = true;
    const selectedOrder = currentTarget.dataset["order"];
    const currentDirection = this.tableContainerTarget.dataset["direction"];
    const currentOrder = this.tableContainerTarget.dataset["order"];
    const nextDirection = this.findNextDirection(
      selectedOrder,
      currentOrder,
      currentDirection
    );
    const url = new URL(window.location);

    var searchString = `?order=${selectedOrder}&direction=${nextDirection}`;
    searchString += `&${url.searchParams.toLocaleString()}`;

    Rails.ajax({
      type: "GET",
      url: `${url.pathname}/${searchString}`,
      success: (response) => {
        this.tableContainerTarget.outerHTML = response.getElementById(
          "table-container"
        ).outerHTML;
        currentTarget.dataset["disabled"] = false;
      },
      error: () => {
        currentTarget.dataset["disabled"] = false;
      },
    });
  }

  findNextDirection(selectedOrder, currentOrder, currentDirection) {
    if (selectedOrder === currentOrder) {
      return currentDirection === "asc" ? "desc" : "asc";
    } else {
      return "asc";
    }
  }
}
