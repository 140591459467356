import { Controller } from "stimulus";
import { Chart } from "chart.js";
import _ from "lodash";
import moment from "moment";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config.js";
const tailwind = resolveConfig(tailwindConfig);

Chart.defaults.global.defaultFontFamily = tailwind.theme.fontFamily["sans"];

export default class extends Controller {
  static targets = ["canvas"];
  connect() {
    this.renderChart();
  }

  get chartData() {
    return JSON.parse(this.data.get("data"));
  }

  // Group the ChartData into years
  get dataByYear() {
    return _.groupBy(this.chartData, (elem) => {
      return elem[0].substring(0, 4);
    });
  }

  // For each year, return an array of data values, filling in any missing
  // elements with "0" Loop over 1..12, collect values where the month number
  // matches, or return 0 if there is no element for that month
  dataByMonth(months) {
    const monthNumbers = [...Array(12).keys()];
    const data = [];
    for (const i in monthNumbers) {
      const paddedMonth = (monthNumbers[i] + 1).toString().padStart(3, "-0");
      const result = months.find((month) => month[0].endsWith(paddedMonth));
      if (Array.isArray(result)) {
        data.push(result[1]);
      } else {
        data.push(0);
      }
    }
    return data;
  }

  // Construct a color pallette. Repeat the pallette as many times as needed to
  // provide enough colors for the number of data series.
  colorPallette(length) {
    var colors = [
      tailwind.theme.colors["blue"]["700"],
      tailwind.theme.colors["blue"]["500"],
      tailwind.theme.colors["blue"]["300"],
      tailwind.theme.colors["orange"]["700"],
      tailwind.theme.colors["orange"]["500"],
      tailwind.theme.colors["orange"]["300"],
      tailwind.theme.colors["teal"]["700"],
      tailwind.theme.colors["teal"]["500"],
      tailwind.theme.colors["teal"]["300"],
      tailwind.theme.colors["pink"]["700"],
      tailwind.theme.colors["pink"]["500"],
      tailwind.theme.colors["pink"]["300"],
    ];

    if (colors.length < length) {
      let j = 0;
      for (let step = colors.length; step < length; step++) {
        colors.push(colors[j]);
        j++;
      }
    } else if (colors.length > length) {
      colors = colors.slice(0, length);
    }
    return colors;
  }

  // Return an array of datasets, one for each year.
  datasets() {
    const dataByYear = this.dataByYear;
    const years = _.keys(dataByYear).reverse();
    const colors = this.colorPallette(years.length);

    var datasets = [];
    var index = years.length;

    for (const year in dataByYear) {
      index = index - 1;
      datasets.push({
        label: year,
        backgroundColor: colors[index],
        data: this.dataByMonth(dataByYear[year]),
        hidden: index >= 3 ? true : false, // Only show the last two years by default
      });
    }
    return datasets;
  }

  numberFormatter() {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  numberToCurrency(val) {
    return this.numberFormatter().format(val);
  }

  get aspectRatio() {
    return window.innerWidth < 500 ? 1.25 : 2.5;
  }

  renderChart() {
    Chart.defaults.global.defaultFontSize = 11;
    var canvas = this.canvasTarget;
    var chart = new Chart(canvas, {
      type: "bar",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: this.datasets(),
      },
      options: {
        responsive: true,
        aspectRatio: this.aspectRatio,
        legend: {
          position: "bottom",
        },
        scaleLabel: (label) => {
          this.numberToCurrency(label);
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
              var index = tooltipItem[0].datasetIndex;
              var setLabel = data.datasets[index].label;
              return tooltipItem[0].label + " " + setLabel;
            },
            label: (tooltipItem) => {
              var value = this.numberToCurrency(tooltipItem.value);
              return value;
            },
          },
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                callback: (value) => {
                  value =
                    Math.abs(value) > 999
                      ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) +
                        "k"
                      : Math.sign(value) * Math.abs(value);
                  return "$" + value;
                },
              },
            },
          ],
        },
      },
    });

    canvas.onclick = (event) => {
      // Chart.js documentation
      // Get the element that was under the click event.
      // https://www.chartjs.org/docs/latest/developers/api.html#getelementatevente
      var clickPoint = chart.getElementAtEvent(event)[0];
      if (clickPoint) {
        // Use Moment.js to construct the date from the label data:
        // https://momentjs.com/guides/#/warnings/js-date/
        // https://momentjs.com/docs/#/parsing/string-format/
        var month = moment(
          clickPoint._view.label + " 1 " + clickPoint._view.datasetLabel,
          "MMMM D YYYY"
        );
        // Parse the date using Moment.js and build a URL to the donations page,
        // filtered by date from the beginning to the end of the month.
        var dateFrom = month.startOf("month").format("L");
        var dateTo = month.endOf("month").format("L");
        var url = `donations?date_from=${dateFrom}&date_to=${dateTo}`;
        Turbo.visit(url); // eslint-disable-line no-undef
      }
    };
  }
}
