import { Controller } from "stimulus";

import Rails from "@rails/ujs";
import Awesomplete from "awesomplete";

export default class extends Controller {
  static targets = [
    "autofill",
    "display",
    "field",
    "suggestion",
    "suggestions"
  ];

  initialize() {
    this.awesomplete = new Awesomplete(this.fieldTarget, {
      minChars: 1,
      maxItems: this.maxItems,
      sort: this.sort,
      list: this.suggestionsTarget
    });
  }

  openSuggestions() {
    // https://github.com/LeaVerou/awesomplete/issues/16754#issuecomment-170226421
    this.awesomplete.minChars = 0;
    this.awesomplete.evaluate();
    this.awesomplete.open();
  }

  autoFill(suggestion) {
    if (suggestion.dataset["autofillUrl"]) {
      Rails.ajax({
        type: "GET",
        url: `${suggestion.dataset["autofillUrl"]}.json`,
        success: response => {
          for (let autofillField of this.autofillTargets) {
            autofillField.value = response[autofillField.dataset["attribute"]];
          }
          for (let autofillDisplay of this.displayTargets) {
            autofillDisplay.innerHTML =
              response[autofillDisplay.dataset["attribute"]];
          }
        }
      });
    }
  }

  clearId(e) {
    if (!e.target.value.trim()) {
      for (let autofillField of this.autofillTargets) {
        if (autofillField.dataset["attribute"] === "id") {
          autofillField.value = "";
        }
      }
    }
  }

  clearDisplay(e) {
    if (!e.target.value.trim()) {
      for (let autofillDisplay of this.displayTargets) {
        autofillDisplay.innerHTML = "";
      }
    }
  }

  suggestionSelected() {
    for (let suggestion of this.suggestionTargets) {
      if (suggestion.innerText.includes(this.fieldTarget.value)) {
        return this.autoFill(suggestion);
      }
    }
  }

  get awesomplete() {
    return this.awesompleteInstance;
  }

  set awesomplete(value) {
    this.awesompleteInstance = value;
  }

  get maxItems() {
    return this.fieldTarget.dataset.maxitens || 10;
  }
}
