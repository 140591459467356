// table_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  click(e) {
    // Allow clicking directly on links in the table...
    if (e.target.tagName != "A") {
      e.preventDefault();

      Turbo.visit(this.url);
    }
  }

  get url() {
    return this.data.get("href");
  }
}
