import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["credit", "debit", "labelCredit", "labelDebit", "notice"];

  initialize() {
    this.balance();
  }

  balance() {
    this.noticeTarget.innerText = "";
    this.labelCreditTarget.innerText = this.totalCredit;
    this.labelDebitTarget.innerText = this.totalDebit;
  }

  isBalanced() {
    return this.totalCredit === this.totalDebit;
  }

  sum(targets) {
    var total = 0;
    targets.forEach(function(d) {
      if (!isNaN(parseFloat(d.value))) {
        total += parseFloat(d.value);
      }
    });
    return total.toFixed(2) || 0;
  }

  submit() {
    event.preventDefault();
    if (this.totalCredit === "0.00" || this.totalDebit === "0.00") {
      this.noticeTarget.innerText = "Debit and credit can't be zero.";
    } else if (!this.isBalanced()) {
      this.noticeTarget.innerText = "Debits and credits don’t balance.";
    } else {
      this.element.submit();
    }
  }

  get totalCredit() {
    return this.sum(this.creditTargets);
  }

  get totalDebit() {
    return this.sum(this.debitTargets);
  }
}
