import { Controller } from "stimulus";
import toMoney from "../shared/modules/to_money";

export default class extends Controller {
  static targets = ["checkbox", "total", "numberOfDonations", "transactionFee"];

  connect() {
    this.updateTotal();
  }

  updateTotal() {
    if (!this.stopUpdatingFees == true) {
      this.transactionFeeTarget.value = this.calculateSelectedFees;
    }
    this.totalTarget.innerHTML = toMoney(this.calculateSelectedTotal);
    this.numberOfDonationsTarget.innerHTML = this.count;
  }

  // Receive the updateFee action from the view. If the user has manuyally
  // entered a value into the fees field, then set `stopUpdatingFees` variable
  // to prevent the manually entered value from being overwrittn. If the user
  // clears out the value, then we assume the user wants the value to be
  // calculated.
  updateFee() {
    if (this.transactionFeeTarget.value == "") {
      this.stopUpdatingFees = false;
    } else {
      this.stopUpdatingFees = true;
    }
    this.totalTarget.innerHTML = toMoney(this.calculateSelectedTotal);
  }

  get calculateSelectedFees() {
    var totalFees = 0;
    this.checkedItems.forEach(function (d) {
      var fee = d.dataset.fee;
      if (typeof fee !== "undefined") {
        totalFees += parseFloat(fee);
      }
    });
    return totalFees.toFixed(2);
  }

  get calculateSelectedTotal() {
    var totalDeposit = 0;
    this.checkedItems.forEach(function (d) {
      var amt = d.dataset.amount;
      totalDeposit += parseFloat(amt);
    });
    totalDeposit = totalDeposit - this.transactionFeeTarget.value;
    return totalDeposit.toFixed(2);
  }

  get count() {
    return this.checkedItems.length;
  }

  get checkedItems() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }
}
