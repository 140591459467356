import _ from "lodash";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template", "destination", "row", "addButton"];
  static values = { min: Number, max: Number };

  clone(event) {
    event.preventDefault();
    var addButton = this.addButtonTarget;
    if (this.maxValue > 0 && this.rowTargets.length >= this.maxValue) {
      addButton.classList.add("shake");
      _.delay(function () {
        addButton.classList.remove("shake");
      }, 1000);
    } else {
      var clone = this.templateTarget.cloneNode(true);
      clone.querySelectorAll("input").forEach(function (e) {
        e.value = "";
      });
      this.renameNodes(clone);
      this.destinationTarget.appendChild(clone);
      clone.querySelector("input:first-of-type").focus();
    }
  }

  purge(event) {
    event.preventDefault();
    const row = event.currentTarget.closest(".row");
    if (this.rowTargets.length > this.minValue) {
      // Mark line for destruction
      const fields = event.currentTarget.closest(".fields");
      if (fields) {
        const destroyField = fields.getElementsByClassName("_destroy")[0];
        if (destroyField) {
          destroyField.value = "true";
        }
      }
      // Remove fields from screen
      row.remove();
    } else {
      row.classList.add("shake");
      _.delay(function () {
        row.classList.remove("shake");
      }, 1000);
    }
  }

  // Re-number the IDs, node names, and 'for' attributes for all elements in the cloned node.
  renameNodes(clone) {
    // n is the number of targets.
    // The new one will be n+1
    var n = this.templateTargets.length;

    // Replace the index in all nodes with id
    clone.querySelectorAll("[id]").forEach(function (node) {
      node.id = node.id.replace(/\d+/, n);
    });

    // Replace the index in all named nodes
    clone.querySelectorAll("[name]").forEach(function (node) {
      node.name = node.name.replace(/\d+/, n);
    });

    // Replace the index in all 'for' nodes
    clone.querySelectorAll("[for]").forEach(function (node) {
      node.htmlFor = node.htmlFor.replace(/\d+/, n);
    });
  }

  // Minimum lines required
  get min() {
    return parseInt(this.data.get("rows-min")) || 1;
  }
}
