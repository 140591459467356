import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {}

  initialize() {
    this.showTab();
  }

  change(event) {
    event.preventDefault();
    this.index = this.tabTargets.indexOf(event.currentTarget);
  }

  showTab() {
    // disable form fields in other tabs
    // enable form fields in current tab

    this.tabTargets.forEach((tab, tabIndex) => {
      const panel = this.panelTargets[tabIndex];
      tab.classList.toggle("active", tabIndex == this.index);

      if (tabIndex == this.index) {
        panel.removeAttribute("hidden");
      } else {
        panel.setAttribute("hidden", true);
      }

      var inputs = panel.querySelectorAll("input,select");
      for (let input of inputs) {
        input.disabled = tabIndex != this.index;
      }
    });
  }

  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }
}
