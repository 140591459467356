import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tagPermissions", "selectedRole"];

  connect() {
    this.togglePermissions();
  }

  togglePermissions() {
    if (
      this.selectedRoleTarget.options[this.selectedRoleTarget.selectedIndex]
        .value == "client"
    ) {
      this.tagPermissionsTarget.classList.remove("hidden");
    } else {
      this.tagPermissionsTarget.classList.add("hidden");
    }
  }
}
