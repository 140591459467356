import { Controller } from "stimulus";
import VMasker from "vanilla-masker";

const RED = "#E3342F";

export default class extends Controller {
  initialize() {
    this.targets.findAll("input").forEach(target => {
      this.maskElement(target);
    });
  }

  execute(event) {
    const element = event.target;
    element.value = VMasker.toPattern(
      element.value,
      element.dataset.pattern
    );
  }

  maskMoney(event) {
    const element = event.target;
    this.maskElement(element);
  }

  maskElement(element) {
    if (/-[^0-9,.]/.test(element.value)) {
      element.style.borderColor = RED;
      return;
    }
    element.style.borderColor = "";
    element.value = parseFloat(element.value.replace(/,/, "."));
    element.value = parseFloat(element.value).toFixed(2);

    if (isNaN(element.value)) {
      element.value = "";
    }
  }
}
