import { Controller } from "stimulus";
import { Chart } from "chart.js";
import moment from "moment";
import Rails from "@rails/ujs";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config.js";
const tailwind = resolveConfig(tailwindConfig);

Chart.defaults.global.defaultFontFamily = tailwind.theme.fontFamily["sans"];

export default class extends Controller {
  static targets = ["canvas"];
  connect() {
    this.load();
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => {
        return response.json();
      })
      .then(chartData => {
        this.renderChart(chartData);
      });
  }

  renderChart(chartData) {
    var canvas = this.element;
    const colors = tailwind.theme.colors;

    let dataset1 = {
      ...chartData.datasets[0],
      ...{
        backgroundColor: colors["blue"][300],
        yAxisID: "y-axis-bar",
        prefix: ""
      }
    };
    let dataset2 = {
      ...chartData.datasets[1],
      ...{
        backgroundColor: colors["blue"][600],
        yAxisID: "y-axis-bar",
        prefix: ""
      }
    };
    let dataset3 = {
      ...chartData.datasets[2],
      ...{
        backgroundColor: colors["orange"][200],
        borderColor: colors["orange"][500],
        fill: false,
        yAxisID: "y-axis-line",
        suffix: "%"
      }
    };

    chartData.datasets = [dataset1, dataset2, dataset3];

    var chart = new Chart(canvas, {
      type: "bar",
      data: chartData,
      options: {
        responsive: true,
        aspectRatio: 2,
        tooltips: {
          mode: "index",
          callbacks: {
            label: (tooltipItem, data) => {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var prefix = dataset.prefix || "";
              var suffix = dataset.suffix || "";

              return label + ": " + prefix + tooltipItem.value + suffix;
            }
          }
        },
        scales: {
          yAxes: [
            {
              type: "linear",
              position: "left",
              id: "y-axis-bar"
            },
            {
              type: "linear",
              position: "right",
              id: "y-axis-line",
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                callback: value => {
                  return value + "%";
                }
              }
            }
          ]
        }
      }
    });

    canvas.onclick = event => {
      var clickPoint = chart.getElementAtEvent(event)[0];
      if (clickPoint) {
        const dataset =
          clickPoint._chart.data.datasets[clickPoint._datasetIndex];
        const label = dataset.labels[clickPoint._index];

        var date = moment(label, "MMMM YYYY");

        var dateFrom = date.startOf("month").format("L");
        var dateTo = date.endOf("month").format("L");
        var params = `?date_from=${dateFrom}&date_to=${dateTo}`;

        Rails.ajax({
          url: params,
          type: "GET",
          dataType: "script"
        });
      }
    };
  }
}
