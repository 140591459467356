import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "destination"];

  copy(e) {
    if (e.target.checked) {
      this.sourceTargets.forEach($source => {
        const $destination = this.destinationTargets.find(
          $target => $target.dataset.attribute === $source.dataset.attribute
        );
        if ($destination && $source.value) {
          $destination.value = $source.value;

          if ($destination.className.includes("fl-")) {
            $destination.parentElement.classList.add("fl-is-active");
          }
        }
      });
    }
  }
}
