import { Controller } from "stimulus";
import { Piklor } from "piklor.js";

export default class extends Controller {
  static targets = ["colorScheme"];

  connect() {
    this.initializePiklor();
  }

  initializePiklor() {
    this.defaultColors = [
      "#606F7B",
      "#E3342F",
      "#DE751F",
      "#F2D024",
      "#1F9D55",
      "#38A89D",
      "#2779BD",
      "#5661B3",
      "#794ACF",
      "#EB5286",
    ];
    const defaultColor = this.data.get("defaultColor");
    const pk = new Piklor(
      ".color-picker",
      [defaultColor, ...this.defaultColors],
      { open: ".picker-wrapper .button", closeOnBlur: true }
    );

    const button = pk.getElm(".picker-wrapper .button");

    pk.colorChosen((color) => {
      button.style.backgroundColor = color;

      this.targets.find("colorScheme").value = color;
      pk.colors = [color, ...this.defaultColors];
      pk.render();
    });
  }
}
