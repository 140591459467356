import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["debit", "credit"];

  fill(event) {
    if (event.target.dataset.type === "debit") {
      this.creditTarget.value = "";
    } else {
      this.debitTarget.value = "";
    }
  }
}
