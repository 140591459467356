import Pikaday from "pikaday";
import "pikaday/scss/pikaday";
import "moment";

import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    minDate: { type: String, default: "" },
    onlyFuture: { type: Boolean, default: false },
    format: { type: String, default: "L" },
    yearRangeMin: { type: Number, default: 1900 },
    yearRangeMax: { type: Number, default: new Date().getFullYear() },
  };
  connect() {
    let opts = {
      setDefaultDate: true,
      field: this.element,
      yearRange: [this.yearRangeMinValue, this.yearRangeMaxValue],
      format: this.formatValue,
    };

    if (this.hasMinDateValue && this.minDateValue != "") {
      let date = new Date(this.minDateValue + " 12:00");
      opts.minDate = date;
    }

    new Pikaday(opts);
  }
}
