/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs";
Rails.start();

// Utility function to require all files in a directory...
function requireAll(r) {
  r.keys().forEach(r);
}

// Add images to the manifest
requireAll(require.context("images/", true, /\.(svg|png|mp4)$/)); // eslint-disable-line no-undef

// support older browsers like Internet Explorer 11
import "@stimulus/polyfills";

// Set up Stimulus and import controllers
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
const application = Application.start();
const controllers = require.context("./controllers", true, /.js$/); // eslint-disable-line no-undef
// Also load shared controllers
const shared = require.context("./shared/controllers", true, /.js$/);
application.load(
  definitionsFromContext(controllers).concat(definitionsFromContext(shared))
);

// Import our app CSS
import "css/devise";

import FloatLabels from "float-labels.js";
var floatLabels;
document.addEventListener("DOMContentLoaded", function () {
  var floatLabels = new FloatLabels(".float-labels", {
    prioritize: "placeholder",
    exclude: ".skip-float-labels",
    style: 2,
  });
});
