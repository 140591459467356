import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [];

  change() {
    Rails.ajax({
      type: "GET",
      dataType: "script",
      url: this.url,
      data: this.serializedData,
    });
  }

  get serializedData() {
    return Rails.serializeElement(this.element);
  }
  get url() {
    return this.data.get("url");
  }
  get output() {
    return this.data.get("prefix") + this.sourceTarget.value;
  }
}
