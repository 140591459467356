import VMasker from "vanilla-masker";

export default function toMoney(entry) {
  const moneyFormatter = {
    precision: 2,
    separator: ".",
    delimiter: ",",
    unit: "$",
    showSignal: true,
  };
  const value = parseFloat(entry).toFixed(2);
  if (value) return VMasker.toMoney(value, moneyFormatter);
  return null;
}
