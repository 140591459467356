import { Controller } from "stimulus";
import { Sortable } from "sortablejs";
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.move.bind(this)
    });
  }

  move(event){
    let resource = event.item.dataset.sortableUrl;
    let data = new FormData();
    data.append("position", event.newIndex + 1);

    Rails.ajax({ url: resource, type: "PATCH", data: data });
  }
}
