import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["target"];

  connect() {}

  touch(event) {
    event.preventDefault();

    this.targetTargets.forEach((target, targetIndex) => {
      if (target.classList.contains(this.toggleClass)) {
        target.classList.remove(this.toggleClass);
      } else {
        target.classList.add(this.toggleClass);
      }
    });
  }

  get toggleClass() {
    return this.data.get("class") || "hidden";
  }
}
