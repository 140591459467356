import { Controller } from "stimulus";
import _ from "lodash";
import Rails from "@rails/ujs";

const TARGETS = [
  "number",
  "amount",
  "contact_name",
  "date_from",
  "date_to",
  "status",
  "starts_on",
  "next_on",
  "deposit_date",
  "deposit_id",
  "item",
  "items",
  "gateway",
  "search",
  "source",
];

export default class extends Controller {
  static targets = TARGETS;

  initialize() {
    this.change = _.debounce(this.change, 500);
    this.parseURLParams();
  }

  change() {
    this.submit();
  }

  select() {
    this.submit();
  }

  submit() {
    // Avoid fire invalid forms for the server
    if (!this.element.checkValidity()) {
      return;
    }
    // Submit element
    Rails.fire(this.element, "submit");
    // this.element.submit();
    window.history.pushState(
      { turbo: {} },
      "",
      `${window.location.pathname}?${Rails.serializeElement(this.element)}`
    );
  }

  // Conserve the input values when searching for something and
  // then paginating results.
  parseURLParams() {
    const params = new URLSearchParams(window.location.search);

    TARGETS.forEach((target) => {
      const element = this.targets.find(target);

      if (element && params.get(target)) {
        element.value = params.get(target);
      }
    });
  }
}
