import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  exclude(event) {
    this.inputTargets
      .filter((el) => el != event.target)
      .forEach((el) => (el.value = null));
  }
}
