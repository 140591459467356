import { Controller } from "stimulus";

const TARGETS = ["year", "period", "custom", "periodFrom", "periodTo"];

export default class extends Controller {
  static targets = TARGETS;

  toggleYear() {
    this.resetRequiredFields();
    this.resetCustomPeriod();
    this.customTarget.checked = false;
  }

  togglePeriod() {
    this.resetRequiredFields();
    this.resetCustomPeriod();
    this.yearTargets.forEach(target => {
      target.required = "required";
    });
  }

  toggleCustom() {
    this.resetRequiredFields();
    this.requiredCustomPeriodRange();
    this.yearTargets.forEach(target => {
      target.checked = false;
    });
    this.customTarget.checked = true;
  }

  resetRequiredFields() {
    TARGETS.forEach(target => {
      const elements = this.targets.findAll(target);
      elements.forEach(el => {
        el.required = "";
      });
    });
  }

  resetCustomPeriod() {
    this.periodFromTarget.value = "";
    this.periodToTarget.value = "";
  }

  requiredCustomPeriodRange() {
    this.periodFromTarget.required = "required";
    this.periodToTarget.required = "required";
  }
}
