import { Controller } from "stimulus";
import Rails from "@rails/ujs";

// Responsible for mounting the FluidPay tokenizer fields and retrieving the
// payment token from the FluidPay API.
// https://sandbox.fluidpay.com/docs/tokenizer/

export default class extends Controller {
  static targets = [
    "token",
    "errorMessageContainer",
    "buttonSubmit",
    "tokenizer",
    "loading",
    "bin",
    "cardBrand",
  ];

  connect() {
    this.tokenizers = this.tokenizerTargets.map((el) =>
      this.mountTokenizer(el)
    );
  }

  get activeTokenizer() {
    var current = this.tokenizerTargets.find((obj) => obj.hidden == false);
    var index = this.tokenizerTargets.indexOf(current);
    return this.tokenizers[index];
  }

  // This method is called on ajax:beforeSend. It communicates with the FluidPay
  // tokenizer and tries to get a payment token, which is put into the hidden
  // `token` field. After it successfully tokenizes the card, the form must be
  // submitted. In order to prevent double-submission of the tokenization form,
  // we check for the existance of a `post-submit` data attribute,which is set
  // after successful tokenization. If the `post-submit` attribute is present,
  // we return `true` so that the event bubbles up and allows the form to be
  // submitted via ajax.
  submit(event) {
    this.buttonSubmitTarget.disabled = true;

    if (this.data.has("post-submit")) {
      return true;
    }
    event.preventDefault();

    if (this.activeTokenizer) {
      // Credit card or bank form is selected. Fire the `submit` action on the
      // active tokenizer.
      this.activeTokenizer.submit();
    } else {
      // A saved payment_method is selected, so skip the tokenizer.
      this.data.set("post-submit", "true");
      this.submitForm();
    }
  }

  handleSuccess(resp) {
    this.data.set("post-submit", "true");
    this.tokenTarget.value = resp.token;
    this.submitForm();
  }

  // Actually submit the form using Ajax or directly
  submitForm() {
    if (this.element.dataset.remote == true) {
      Rails.fire(this.element, "submit");
    } else {
      this.element.submit();
    }
  }

  handleError(resp) {
    switch (resp.msg) {
      case "form validation":
        this.handleValidation(resp);
        break;
      default:
        this.errorMessage = resp.msg;
    }
  }

  handleValidation(resp) {
    this.errorMessage = "There was an error with your payment information";
    this.buttonSubmitTarget.disabled = false;
  }

  handleValidCard(card) {
    this.binTarget.value = card.bin.bin;
    this.cardBrandTarget.value = card.bin.card_brand;
  }

  set errorMessage(message) {
    this.errorMessageContainerTarget.innerHTML = message;
    this.errorMessageContainerTarget.removeAttribute("hidden");
  }

  clearErrorMessage() {
    this.errorMessageContainerTarget.innerHTML = "";
    this.errorMessageContainerTarget.setAttribute("hidden", true);
  }

  types() {
    var types = this.data.get("types").split(",");
    return types;
  }

  mountTokenizer(container) {
    var cont = this;
    return new Tokenizer({
      url: this.data.get("url"),
      apikey: this.data.get("publicKey"),
      container: container,
      onLoad: () => {
        this.loadingTargets.forEach((el) => {
          el.hidden = true;
        });
      },
      // container: document.getElementById("TokenFields"),
      // Callback after submission request has been made
      // See Advanced -> Methods for breakdown of specific fields
      submission: (resp) => {
        switch (resp.status) {
          case "success":
            cont.handleSuccess(resp);
            break;
          case "error":
            cont.handleError(resp);
            break;
          case "validation":
            cont.handleValidation(resp);
            break;
        }
      },
      validCard: (card) => {
        cont.handleValidCard(card);
      },
      onPaymentChange: (type) => {
        cont.clearErrorMessage();
      },
      settings: {
        payment: {
          types: [container.dataset.mount],
          ach: {
            sec_code: "web",
          },
          card: {
            requireCVV: true,
          },
          user: {
            showInline: true,
            showName: true,
            showEmail: true,
            showPhone: true,
            showTitle: true,
          },
        },
        styles: {
          body: {},
          select: {
            height: "56px",
            "border-radius": "0.5rem",
            "border-color": "#e2e8f0",
            "border-width": "1px",
          },
          input: {
            height: "56px",
            padding: "8px 12px",
            "border-radius": "0.5rem",
            "border-color": "#e2e8f0",
            "border-width": "1px",
            "font-size": "18px",
            "font-weight": "500",
          },
          ".card .cc input": {
            padding: "0 0 0 44px",
          },
          ".card .cc .cc-icon": {
            "padding-left": "8px",
          },
          ".payment .selection .item": {
            "font-size": "18px",
          },
          ".ach .fieldsetrow .type": {
            flex: "0 0 110px",
          },
        },
      },
    });
  }
}
