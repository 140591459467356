import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "amount",
    "email",
    "phoneNumber",
    "firstName",
    "gatewayContainer",
    "lastName",
    "paymentMethodType",
    "token",
    "transactionId",
    "routingNumber",
    "accountNumber",
    "accountType",
    "accountName",
    "gatewayResponse",
    "errorMessageContainer",
  ];

  initialize() {
    this.boundEventListener = this.eventListener.bind(this);
    this.updateAmount();
  }

  connect() {
    window.removeEventListener("message", this.boundEventListener);
    window.addEventListener("message", this.boundEventListener);
  }

  // Global event listener that listens for message from
  // different contexts of the gateway iframe and then parses the data
  // and redirects to the right flow as token or payment method update
  // payment methods mapping can be a constant outside the function
  eventListener(event) {
    this.updateAmount();
    if (!event.origin.match(/.*\.dimepayments\.com$/)) {
      console.warn("Message received from invalid origin:", event.origin);
      return;
    }

    const decoded = this.decodeEventData(event.data);

    try {
      const {
        paymentType,
        transactionType,
        transactionNumber,
        statusCode,
        statusText,
        multiUseToken,
        customFields: { achDetails } = {},
      } = decoded;

      if (statusCode && statusCode.toString() != "00") {
        this.handleFailure(statusCode, statusText, transactionNumber);
        this.createFailureLog(decoded);
        return;
      }

      if (paymentType) {
        this.handlePaymentType(paymentType);
        return;
      }

      if (transactionType) {
        this.handleTransactionType(
          transactionType,
          multiUseToken,
          transactionNumber,
          achDetails,
          decoded
        );
        return;
      }

      console.log("Message received not supported:", decoded);
    } catch (e) {
      console.log("Error parsing message:", e);
    }
  }

  decodeEventData(data) {
    return typeof data === "string" ? JSON.parse(data) : data;
  }

  handlePaymentType(paymentType) {
    const paymentMapping = {
      cc: "card",
      ach: "bank",
    };
    this.paymentMethodTypeTarget.value = paymentMapping[paymentType];

    // trigger change event to update the amount and fees
    const event = new Event("change", { bubbles: true });
    this.paymentMethodTypeTarget.dispatchEvent(event);
  }

  handleTransactionType(
    transactionType,
    multiUseToken,
    transactionNumber,
    achDetails,
    gatewayResponse
  ) {
    if (transactionType === "Credit Card") {
      this.tokenTarget.value = multiUseToken;
    } else if (transactionType === "ACH") {
      this.routingNumberTarget.value = achDetails.routingNumber;
      this.accountNumberTarget.value = achDetails.accountNumber;
      this.accountTypeTarget.value = achDetails.accountType;
      this.accountNameTarget.value = achDetails.accountName;
    }

    this.transactionIdTarget.value = transactionNumber;
    this.gatewayResponseTarget.value = JSON.stringify(gatewayResponse);
    this.submitForm();
  }

  handleFailure(statusCode, statusText, transactionNumber) {
    let message = `Failed: ${statusCode} - ${statusText} | ${transactionNumber}`;
    console.log("Failure:", statusCode, statusText, transactionNumber);
    this.errorMessageContainerTarget.innerHTML = message;
    this.errorMessageContainerTarget.removeAttribute("hidden");
  }

  createFailureLog(response) {
    let resource = this.element.dataset.failureLogRequestUrl;
    let data = new FormData();
    data.append("body", JSON.stringify(response));

    Rails.ajax({ url: resource, type: "POST", data: data });
  }

  updateAmount(event) {
    const amount = parseFloat(this.amountTarget.value) || 0;
    const firstName = this.firstNameTarget.value;
    const lastName = this.lastNameTarget.value;
    const email = this.emailTarget.value;
    const phone = this.phoneNumberTarget.value;
    const iframe = this.gatewayContainerTarget.querySelector("iframe");

    if (iframe && amount > 0) {
      iframe.contentWindow.postMessage(
        {
          amount: amount,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
        },
        "*"
      );
    }
  }

  // Actually submit the form using Ajax or directly
  submitForm() {
    if (this.element.dataset.remote == true) {
      Rails.fire(this.element, "submit");
    } else {
      this.element.submit();
    }
  }
}
