import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "row"];

  all(evt) {
    evt.target.checked ? this.checkAll() : this.uncheckAll();
  }

  toggleCheckbox() {
    var i = this.rowTargets.indexOf(event.currentTarget);
    var checkbox = this.checkboxTargets[i];

    if (checkbox != event.target) {
      checkbox.checked = checkbox.checked ? false : true;
      var changeEvent = new Event("change");
      checkbox.dispatchEvent(changeEvent);
    }
  }

  checkAll() {
    this.checkboxes.forEach(el => {
      el.checked = true;
    });
  }

  uncheckAll() {
    this.checkboxes.forEach(el => {
      el.checked = false;
    });
  }

  get checkboxes() {
    return this.checkboxTargets;
  }
}
