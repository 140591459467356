import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["paymentType", "wrapper"];

  initialize() {
    this.choose(this.paymentTypeTarget.value);
  }

  toggle(event) {
    const paymentMethod = event.target.value;
    this.choose(paymentMethod);
  }

  choose(paymentMethod) {
    this.wrapperTargets.forEach((el) => {
      if (el.dataset.key === paymentMethod) {
        el.removeAttribute("hidden");
        document.getElementById(el.dataset.mount).removeAttribute("hidden");
      } else {
        el.setAttribute("hidden", true);
        document.getElementById(el.dataset.mount).setAttribute("hidden", true);
      }
    });
  }
}
