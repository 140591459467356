/* eslint-disable quotes */
const colors = require("tailwindcss/colors");

module.exports = {
  important: true,
  content: [
    './public/*.html',
    './app/helpers/**/*.rb',
    './app/frontend/**/*.js',
    './app/views/**/*',
  ],
  plugins: [require("@tailwindcss/forms")],
  theme: {
    fontSize: {
      xs: "0.75rem",
      sm: "0.875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "3rem",
      "6xl": "4rem",
    },

    fontFamily: {
      sans: [
        "Nunito",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        '"Noto Sans"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: [
        "Constantia",
        "Lucida Bright",
        "Lucidabright",
        "Lucida Serif",
        "Lucida",
        "DejaVu Serif",
        "Bitstream Vera Serif",
        "Liberation Serif",
        "Georgia",
        "serif",
      ],
      mono: [
        "Menlo",
        "Monaco",
        "Consolas",
        "Liberation Mono",
        "Courier New",
        "monospace",
      ],
    },
    extend: {
      margin: {
        96: "24rem",
        128: "32rem",
      },
      boxShadow: {
        xl: "0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)",
      },
      screens: {
        sm: "640px",
        md: "786px",
        lg: "1024px",
        xl: "1280px",
        print: {
          raw: "print",
        },
      },
      maxWidth: {
        xs: "20rem",
        sm: "30rem",
        md: "40rem",
        lg: "50rem",
        xl: "60.5rem",
        "2xl": "70rem",
        "3xl": "80rem",
        "4xl": "90rem",
        "5xl": "100rem",
        full: "100%",
      },
      colors: {
        brand: "#1ECE81",
        blue: colors.blue,
        teal: colors.teal,
        red: colors.red,
        yellow: colors.yellow,
        orange: colors.orange,
        green: {
          ...colors.green,
          500: "#1ECE81", // brand green
        },
        gray: {
          100: "#F6F6F6",
          200: "#eeeeee",
          300: "#e0e0e0",
          400: "#bdbdbd",
          500: "#9e9e9e",
          600: "#757575",
          700: "#616161",
          800: "#424242",
          900: "#212121",
        },
      },
    },
  },
  variants: {
    opacity: ["responsive", "hover"],
  },
};
