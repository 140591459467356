import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "button", "phoneNumber", "notice"];

  connect() {}

  // Resend authentication code
  resend(event) {
    event.preventDefault();

    Rails.ajax({
      type: "POST",
      url: event.target.href,
      success: (response) => {
        if (response.success) {
          this.handleMessage("A new verification code was sent you.");
        } else {
          this.handleMessage(`${response.format} is not valid phone number.`);
          this.buttonProcessingMessage(false);
        }
      },
    });
  }

  handleMessage(message) {
    this.noticeTarget.classList.remove("hidden");
    this.noticeTarget.textContent = message;
  }

  buttonProcessingMessage(processing) {
    this.buttonTarget.value = processing ? "Sending..." : "Send code";
  }
}
