import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "output"];

  connect() {
    this.mirror();
  }

  mirror() {
    this.outputTargets.forEach((target) => {
      switch (target.nodeName) {
        case "INPUT":
          target.value = this.output;
          break;
        default:
          target.innerHTML = this.output;
          break;
      }
    });
  }

  get output() {
    return this.data.get("prefix") + this.sourceTarget.value;
  }
}
