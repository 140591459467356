import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkFields", "paymentMethod"];

  connect() {
    this.maybeShowCheckFields();
  }

  maybeShowCheckFields() {
    var selected = this.paymentMethodTarget.options[
      this.paymentMethodTarget.selectedIndex
    ];
    this.toggleCheckFields(selected.text === "Check");
  }

  toggleCheckFields(enabled = false) {
    const element = this.checkFieldsTarget;

    if (enabled) {
      element.classList.remove("hidden");
      element.classList.add("flex");
    } else {
      element.classList.add("hidden");
      element.classList.remove("flex");
    }
  }
}
