import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["url", "areaCode", "phoneNumber"];

  choosePhone() {
    const url = this.urlTarget.value;
    const data = new FormData();
    data.append("phone_number", this.phoneNumberTarget.value);
    data.append("area_code", this.areaCodeTarget.value);

    Rails.ajax({
      type: "POST",
      url: url,
      data: data,
      success: response => {
        window.location = response.redirect_to;
      }
    });
  }
}
