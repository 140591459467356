import { Controller } from "stimulus";
import { Chart } from "chart.js";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config.js";
const tailwind = resolveConfig(tailwindConfig);

Chart.defaults.global.defaultFontFamily = tailwind.theme.fontFamily["sans"];

window.chartColors = {
  red: "#ff0000"
};

export default class extends Controller {
  static targets = ["canvas"];
  connect() {
    this.load();
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => {
        return response.json();
      })
      .then(chartData => {
        this.renderChart(chartData);
      });
  }

  get aspectRatio() {
    return this.data.get("aspectRatio") || 3;
  }
  get rotation() {
    return (this.data.get("rotation") || -0.5) * Math.PI;
  }
  get circumference() {
    return (this.data.get("circumference") || 2) * Math.PI;
  }
  get displayLegend() {
    return (this.data.get("legend") || "true") === "true";
  }
  get legendPosition() {
    return this.data.get("legendPosition") || "right";
  }
  get cutoutPercentage() {
    return this.data.get("cutoutPercentage") || 60;
  }
  get legendAlign() {
    return this.data.get("legendAlign");
  }

  round_to_precision(x, precision) {
    var y = +x + (precision === undefined ? 0.5 : precision / 2);
    return y - (y % (precision === undefined ? 1 : +precision));
  }

  renderChart(chartData) {
    var canvas = this.element;
    const colors = tailwind.theme.colors;

    chartData.datasets.forEach(dataset => {
      dataset.backgroundColor = [
        colors["red"][500],
        colors["orange"][500],
        colors["yellow"][500],
        colors["green"][500],
        colors["blue"][500],
        colors["purple"][500],
        colors["indigo"][500],
        colors["teal"][500]
      ];
    });

    new Chart(canvas, {
      type: "doughnut",
      data: chartData,
      options: {
        legend: {
          display: this.displayLegend,
          position: this.legendPosition,
          align: this.legendAlign,
          labels: {
            lineWidth: 150,
            boxWidth: 12
          }
        },
        aspectRatio: this.aspectRatio,
        cutoutPercentage: this.cutoutPercentage,
        rotation: this.rotation,
        circumference: this.circumference,
        tooltips: {
          mode: "index",
          callbacks: {
            title: (tooltipItem, data) => {
              var rangeLabel = data.labels[tooltipItem[0].index];
              return rangeLabel;
            },
            label: (tooltipItem, data) => {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var label = dataset.label;
              var value = this.round_to_precision(
                dataset.data[tooltipItem.index],
                0.5
              );
              return value + label;
            }
          }
        }
      }
    });
  }
}
