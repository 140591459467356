import { Controller } from "stimulus";

export default class extends Controller {
  previewHTML(event) {
    const destination = this.targets.find(event.target.dataset.preview);
    if (event.target.value == '') {
      destination.innerHTML = event.target.dataset.default || '';
    } else {
      destination.innerHTML = event.target.value;
    }
    
  }

  previewImage(event) {
    const destination = this.targets.find(event.target.dataset.preview);
    const image = document.createElement('img'); 

    const files = event.target.files;
    const lastFile = files[files.length - 1];

    if (files && lastFile) {
      var reader = new FileReader();

      reader.onload = (e) => {
        image.src = e.target.result;
        image.classList.remove("hidden");
      };

      reader.readAsDataURL(lastFile);
    }

    destination.innerHTML = '';
    destination.appendChild(image);
  }
}
